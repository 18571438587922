<template>
    <v-sheet class="custom-timetable">
        <v-toolbar flat class="calendar-header flex-md-column" height="auto">
            <v-toolbar-items class="my-2 toolbar-left-items flex-grow-1 align-center flex-sm-row flex-column">
                <div class="d-flex align-center">
                    <v-btn text depressed icon class="my-auto mx-3" @click="_onDateControlClick('prev')" :class="{'pointer-loading': loading}">
                        <v-icon color="colorWhite">{{ icon.mdiChevronLeft }}</v-icon>
                    </v-btn>
                    <v-btn text depressed icon class="my-auto mx-3" @click="_onDateControlClick('next')" :class="{'pointer-loading': loading}">
                        <v-icon color="colorWhite">{{ icon.mdiChevronRight }}</v-icon>
                    </v-btn>
                </div>

                <v-toolbar-title class="colorWhite--text ml-sm-6 subtitle-2">{{ dateDisplayText }}</v-toolbar-title>
            </v-toolbar-items>
            <v-spacer></v-spacer>
            <v-toolbar-items class="my-2 toolbar-right-items">
                <v-btn @click="_onCalendarTypeChanged('day')" class="my-auto ml-3"
                    :class="{ 'accent': calendarType === 'day', 'pointer-loading': loading }"
                    :color="calendarType === 'day' ? 'colorBlack' : 'colorWhite'" outlined>日</v-btn>
                <v-btn @click="_onCalendarTypeChanged('week')" class="my-auto  ml-3"
                    :class="{ 'accent': calendarType === 'week', 'pointer-loading': loading }"
                    :color="calendarType === 'week' ? 'colorBlack' : 'colorWhite'" outlined>週</v-btn>
            </v-toolbar-items>
        </v-toolbar>

        <v-container class="custom-timetable__body pa-0" v-if="loading">
            <div class="text-center colorBlack--text d-flex align-center justify-center" style="min-height: 180px;">
                資料處理中...</div>
        </v-container>

        <v-container fluid v-if="!loading && calendarType === 'day'" class="custom-timetable__body pa-0">
            <div class="d-flex align-center" v-if="!displayEmpty">
                <template v-for="time in timeList">
                    <span class="custom-timetable__col custom-timetable__col__header">{{
                        time.start_time }} - {{ time.end_time }}</span>
                </template>
            </div>
            <div class="d-flex" v-if="!displayEmpty">
                <template v-for="time in dailyTableData">
                    <div class="custom-timetable__col">
                        <template v-for="item in time">
                            <v-card class="mb-4 custom-timetable-card" @click="_navToDetails(item.id)">
                                <v-card-text class="pa-0 ma-0">
                                    <div class="d-flex align-start justify-space-between custom-timetable-card__top"
                                        :style="{ background: item.color }">
                                        <span>{{ item.tutor }}</span>
                                        <span>{{ item.current_student_num }}/{{ item.max_student }}人</span>
                                    </div>
                                    <div class="custom-timetable-card__bot">
                                        <div class="course-name">{{ item.course_name }}</div>
                                        <ul class="course-info-list">
                                            <li><v-icon color="colorBlack" size="12"
                                                    class="fi fi-rr-graduation-cap mr-2"></v-icon>{{ item.grade |
                                                        formatGrade }}</li>
                                            <li><v-icon color="colorBlack" size="12"
                                                    class="fi fi-rr-door-open mr-2"></v-icon>{{ item.classroom }}
                                            </li>
                                        </ul>
                                       
                                        <ul class="student-attendance-list" v-if="$validate.DataValid(item.student_data)">
                                            <li v-for="data in item.student_data" :key="data.id">
                                                <div class="attend-check" :class="data.attendance"></div>
                                                <div class="gender-dot" :class="data.gender === 'M' ? 'gender-m' : data.gender === 'F' ? 'gender-f' : ''"></div>
                                                <span>{{ data.name }}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </v-card-text>
                            </v-card>
                        </template>
                    </div>
                </template>
            </div>
            <div v-else class="text-center colorBlack--text d-flex align-center justify-center" style="min-height: 180px;">
                沒有課堂</div>
        </v-container>
        <v-container fluid v-else-if="!loading && calendarType === 'week'" class="custom-timetable__body pa-0">
            <div class="d-flex align-center" v-if="!displayEmpty">
                <template v-for="date in datesList">
                    <span class="custom-timetable__col custom-timetable__col__header">
                        <div class="caption mb-1">{{ _formatWeekday(date) }}</div>
                        <div class="body-2">{{ _formateShortDay(date) }}</div>
                    </span>
                </template>
            </div>

            <div class="d-flex" v-if="!displayEmpty">
                <template v-for="date in weeklyTableData">
                    <div class="custom-timetable__col">
                        <template v-for="item in date">
                            <v-card class="mb-4 custom-timetable-card" @click="_navToDetails(item.id)">
                                <v-card-text class="pa-0 ma-0">
                                    <div class="custom-timetable-card__top" :style="{ background: item.color }">
                                        <div class="d-flex align-start justify-space-between">
                                            <span>{{ item.tutor }}</span>
                                            <span>{{ item.current_student_num }}/{{ item.max_student }}人</span>
                                        </div>
                                        <span>{{ item.start_time }}-{{ item.end_time }}</span>
                                    </div>

                                    <div class="custom-timetable-card__bot">
                                        <div class="course-name">{{ item.course_name }}</div>
                                        <ul class="course-info-list">
                                            <li>
                                                <v-icon color="colorBlack" size="12"
                                                    class="fi fi-rr-graduation-cap mr-2"></v-icon>
                                                {{ item.grade | formatGrade }}
                                            </li>
                                        </ul>
                                    </div>
                                </v-card-text>
                            </v-card>
                        </template>
                    </div>
                </template>
            </div>
            <div v-else class="text-center colorBlack--text d-flex align-center justify-center" style="min-height: 180px;">
                沒有課堂</div>
        </v-container>
    </v-sheet>
</template>

<script>
import {
    mdiChevronLeft,
    mdiChevronRight
} from '@mdi/js'
export default {
    name: 'CustomTimetable',
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
        timetableData: {
            type: Array,
            required: true,
            default: () => []
        },
    },
   
    computed: {
        dateDisplayText() {
            if (this.$validate.DataValid(this.datesList)) {
                if (this.calendarType === 'week') {
                    return `${this._formatDateInZh(this.$formatter.convertStrToDate(this.datesList[0]))} 至 ${this._formatDateInZh(this.$formatter.convertStrToDate(this.datesList[this.datesList.length - 1]))}`;
                } else {
                    return this._formatDateInZh(this.$formatter.convertStrToDate(this.datesList[0]));
                }
            }

            return this._formatDateInZh(new Date());
        },
        todayStr() {
            return this.$formatter.formatDate(new Date());
        },
        timeList() {
            const startTime = this.$settings.startTime;
            const endTime = this.$settings.endTime;
            const list = [];
            const endTimeDate = new Date(`2023-01-01T${endTime}:00+08:00`);
            const tempDate = new Date(`2023-01-01T${startTime}:00+08:00`);

            while (tempDate.getTime() <= endTimeDate.getTime()) {
                list.push(`${this.$formatter.pad(tempDate.getHours())}:${this.$formatter.pad(tempDate.getMinutes())}`);
                tempDate.setTime(tempDate.getTime() + this.interval * 60 * 1000);
            }

            const timeSlotList = [];
            for (let i = 0; i < list.length; i++) {
                if (i === list.length - 1) {
                    if (list[i] !== endTime) {
                        timeSlotList.push({
                            start_time: list[i],
                            end_time: endTime,
                        });
                    }
                } else {
                    timeSlotList.push({
                        start_time: list[i],
                        end_time: list[i + 1],
                    });
                }
            }

            return timeSlotList;
        },
        dailyTableData() {
            if (this.calendarType === 'day') {
                const dateData = this.timetableData.filter(el => el.date === this.calendarValue);
                const tableList = [];
                this.timeList.forEach(timeSlot => {
                    const startTimeDate = new Date(`2023-01-01T${timeSlot.start_time}:00+08:00`);
                    const endTimeDate = new Date(`2023-01-01T${timeSlot.start_time}:00+08:00`);
                    endTimeDate.setTime(endTimeDate.getTime() + (this.interval - 1) * 60 * 1000); // e.g. 08:00 - 08:59

                    const inTimeData = dateData.filter(el => {
                        const tempTimeDate = new Date(`2023-01-01T${el.start_time}:00+08:00`);
                        return tempTimeDate.getTime() >= startTimeDate.getTime() && tempTimeDate.getTime() <= endTimeDate.getTime();
                    })

                    inTimeData.sort((a, b) => {
                        const aTimeDate = new Date(`2023-01-01T${a.start_time}:00+08:00`);
                        const bTimeDate = new Date(`2023-01-01T${b.start_time}:00+08:00`);
                        return aTimeDate.getTime() - bTimeDate.getTime();
                    })

                    tableList.push(inTimeData);
                });

                return tableList;
            }

            return [];
        },
        weeklyTableData() {
            if (this.calendarType === 'week') {
                const list = [];
                this.datesList.forEach(date => {
                    const inDateData = this.timetableData.filter(el => el.date === date);
                    inDateData.sort((a, b) => {
                        const aTimeDate = new Date(`2023-01-01T${a.start_time}:00+08:00`);
                        const bTimeDate = new Date(`2023-01-01T${b.start_time}:00+08:00`);
                        return aTimeDate.getTime() - bTimeDate.getTime();
                    })
                    list.push(inDateData);
                })
                return list;
            }

            return [];
        },
        displayEmpty() {
            let dailyLen = 0;
            this.dailyTableData.forEach(el => {
                dailyLen += el.length;
            })
            let weeklyLen = 0;
            this.weeklyTableData.forEach(el => {
                weeklyLen += el.length;
            })
            return dailyLen === 0 && weeklyLen === 0;
        },
    },
    data: () => ({
        icon: {
            mdiChevronLeft,
            mdiChevronRight
        },
        interval: 60, // minute

        calendarType: 'day', // 'day' | 'week'
        calendarValue: '', // YYYY-MM-DD
        datesList: [],
    }),
    methods: {
        setDateList(list) {
            this.datesList = list;
        },
        setCurrentValue(value) {
            this.calendarValue = value;
        },
        setCalendarType(value) {
            this.calendarType = value;
        },
        getDatesList() {
            const currentDate = this.$formatter.convertStrToDate(this.calendarValue);
            let tempDatesList = [];
            if (this.calendarType === 'day') {
                tempDatesList = [this.calendarValue];
            } else if (this.calendarType === 'week') {
                const currentWeekday = currentDate.getDay();
                const firstDateOfWeek = this.$formatter.convertStrToDate(this.calendarValue);
                firstDateOfWeek.setDate(currentDate.getDate() - currentWeekday);
                for (let i = 0; i < 7; i++) {
                    tempDatesList.push(this.$formatter.formatDate(firstDateOfWeek));
                    firstDateOfWeek.setDate(firstDateOfWeek.getDate() + 1);
                }
            }
            return tempDatesList;
        },
        _onCalendarTypeChanged(type) {
            if (this.loading || this.calendarType === type) {
                return;
            }

            this.calendarType = type;
            let currentDateStr = this.todayStr;
            if (this.$validate.DataValid(this.calendarValue)) {
                currentDateStr = this.calendarValue;
            }

            const currentDate = this.$formatter.convertStrToDate(currentDateStr);
            this.calendarValue = this.$formatter.formatDate(currentDate);
            this.datesList = this.getDatesList();
            this.$emit('type-update', this.calendarType);
            this.$emit('dates-update', this.datesList);
        },
        _onDateControlClick(action) {
            if (this.loading) {
                return;
            }
            let currentDateStr = this.todayStr;
            if (this.$validate.DataValid(this.calendarValue)) {
                currentDateStr = this.calendarValue;
            }

            const currentDate = this.$formatter.convertStrToDate(currentDateStr);
            this.datesList = [];
            if (this.calendarType === 'day') {
                if (action === 'prev') {
                    currentDate.setDate(currentDate.getDate() - 1);
                } else {
                    currentDate.setDate(currentDate.getDate() + 1);
                }

                this.calendarValue = this.$formatter.formatDate(currentDate);
                this.datesList = this.getDatesList();
            } else if (this.calendarType === 'week') {
                const currentWeekday = currentDate.getDay();
                if (action === 'prev') {
                    const lastDateOfPrevWeek = this.$formatter.convertStrToDate(currentDateStr);
                    lastDateOfPrevWeek.setDate(currentDate.getDate() - currentWeekday - 1);
                    this.calendarValue = this.$formatter.formatDate(lastDateOfPrevWeek);
                } else {
                    const lastDateOfNextWeek = this.$formatter.convertStrToDate(currentDateStr);
                    lastDateOfNextWeek.setDate(currentDate.getDate() + (6 - currentWeekday) + 7);
                    this.calendarValue = this.$formatter.formatDate(lastDateOfNextWeek);
                }
                this.datesList = this.getDatesList();
            }

            this.$emit('dates-update', this.datesList);
        },
        _formatWeekday(d) {
            const day = this.$formatter.getDay(d);
            return `星期${this.$formatter.formatWeekday(day)}`;
        },
        _formatDateInZh(d) {
            return `${d.getFullYear()}年${d.getMonth() + 1}月${d.getDate()}日`;
        },
        _formateShortDay(d) {
            const dDate = this.$formatter.convertStrToDate(d);
            return `${this.$formatter.pad(dDate.getDate())} - ${this.$formatter.pad(dDate.getMonth() + 1)}`

        },
        _navToDetails(id) {
            this.$router.push({ name: 'ClassDetails', params: { id: id }})
        }
    },
}

</script>

<style lang="scss" scoped>
::v-deep.custom-timetable {
    &>.custom-timetable__body {
        overflow-x: auto;

        & .custom-timetable__col {
            width: 200px;
            min-width: 200px;
            padding-left: 8px;
            padding-right: 8px;
        }

        & .custom-timetable__col__header {
            font-size: .875rem;
            color: var(--v-colorBlack-base);
            text-align: center;
            padding-top: 16px;
            padding-bottom: 16px;
            display: block;
        }
    }


    & .custom-timetable-card {
        border-radius: 5px;
        overflow: hidden;
        cursor: pointer;

        & .custom-timetable-card__top {
            padding: 5px 10px;
            color: #ffffff;
            font-size: .75rem;
            line-height: 1.5;
        }

        & .custom-timetable-card__bot {
            color: var(--v-colorBlack-base);
            padding: 10px;
            background: #EEEEEE;

            &>.course-name {
                font-size: .75rem;
                line-height: 1.5;
                font-weight: 600;
                margin-bottom: 10px;
            }

            &>.course-info-list {
                padding: 0;
                list-style: none;

                &>li {
                    position: relative;
                    color: var(--v-colorBlack-base);
                    font-size: 10px;
                    margin: 5px 0;
                }
            }

            &>.student-attendance-list {
                padding: 0;
                list-style: none;
                margin-top: 10px;

                &>li {
                    position: relative;
                    color: var(--v-colorBlack-base);
                    font-size: 10px;
                    margin: 5px 0;
                    display: flex;
                    align-items: center;
                    line-height: 1.5;

                    &>.attend-check {
                        width: 10px;
                        min-width: 10px;
                        height: 10px;
                        border-radius: 2px;
                        background: #A7A7A7;
                        margin-right: 8px;

                        &.attend {
                            background: #27C712;
                        }

                        &.absent {
                            background: var(--v-red-base);
                        }
                    }
                }
            }
        }
    }
}
</style>